<template>
  <keep-alive>
  <page-content>

    <common-table
      ref="table"
      path="web/mall/order"
      :query-params="queryParams"
      :columns="columns">

      <template slot="search">
        <a-radio-group v-model="queryParams.status" @change="$refs.table.handleReset()">
          <a-radio-button :value="null">全部</a-radio-button>
          <a-radio-button :value="0">未付款</a-radio-button>
          <a-radio-button :value="1">待发货</a-radio-button>
          <a-radio-button :value="2">已发货</a-radio-button>
          <a-radio-button :value="4">退款中</a-radio-button>
          <a-radio-button :value="5">已退款</a-radio-button>
          <a-radio-button :value="3">已完成</a-radio-button>
        </a-radio-group>
      </template>

      <template slot="goods" slot-scope="{record}">
        <item-goods v-for="item in record.goodsList" :item="item" style="border-right: 1px solid #F0F0F0"></item-goods>
      </template>

      <template slot="info" slot-scope="{record}">
        <div>订单编号：{{record.orderId}}</div>
        <div>下单时间：{{record.createdAt}}</div>
      </template>

      <template slot="address" slot-scope="{record}">
        <div>{{JSON.parse(record.addressJson).userName}} {{JSON.parse(record.addressJson).telNumber}}</div>
        <div>
          {{JSON.parse(record.addressJson).provinceName}}
          {{JSON.parse(record.addressJson).cityName}}
          {{JSON.parse(record.addressJson).countyName}}
          {{JSON.parse(record.addressJson).detailInfo}}
        </div>
      </template>


      <template slot="operation" slot-scope="{record}">
        <div style="display: flex;flex-direction: column;align-items: flex-end">
          <a-button class="order-btn" size="small" @click="view(record)">查看详情</a-button>
          <a-button v-if="record.status==1" class="order-btn" size="small" @click="clickExpress(record)">发货</a-button>
          <a-button v-if="record.status==2" class="order-btn" size="small" @click="clickExpress(record)">修改发货信息</a-button>
        </div>
      </template>

    </common-table>


    <input-dialog ref="inputDialog"></input-dialog>

  </page-content>
  </keep-alive>
</template>

<script>
  import ItemGoods from "../_components/item-goods"
  export default {
    components:{ItemGoods},
    data() {
      return {
        queryParams:{
          status:null
        },
      }
    },
    created() {
      if(this.$route.query.status){
        this.queryParams.status = parseInt(this.$route.query.status)
      }
    },
    computed: {
      columns() {

        return [
          {
            title: '订单商品',
            dataIndex: 'goodsList',
            scopedSlots: {customRender: 'goods'}
          },
          {
            title: '订单信息',
            dataIndex: 'orderId',
            scopedSlots: {customRender: 'info'}
          },
          {
            title: '收货地址',
            dataIndex: 'address',
            scopedSlots: {customRender: 'address'}
          },
          {
            title: '状态',
            dataIndex: 'status',
            customRender(text){
              return ["待付款","待商家发货","待买家收货","已收货","退款中","已退款"][parseInt(text)]
            }
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
          }]
      }
    },

    methods: {

      add() {
      },
      view(record){
        this.$router.push("/mall/order/"+record.id)
      },
      clickExpress(record){
        let self = this
        this.$refs.inputDialog.show({
          title:"请填写快递信息",
          value:"",
          placeholder:"请填写顺丰物流单号",
          onOk(value){
            let orderId = record.id
            self.$post(`web/mall/order/${orderId}/updateExpress`,{
              company:"顺丰",
              number:value
            }).then(suc=>{
              self.getData()
            })
          }
        })
      },
      getData() {
        this.$refs.table.getData()
      },

      remove(record) {
        let that = this
        this.$confirm({
          title: '确定删除 ' + record.text,
          content: '该操作无法撤销',
          centered: true,
          onOk() {
            that.$delete('menu/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getData()
            })
          }
        })
      },

    }
  }
</script>
<style lang="less" scoped>
  .order-btn{
    background: transparent;border: 0;box-shadow: 0 0 0 0 ;color: #0084FF;
  }
</style>
