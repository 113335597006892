var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('keep-alive',[_c('page-content',[_c('common-table',{ref:"table",attrs:{"path":"web/mall/order","query-params":_vm.queryParams,"columns":_vm.columns},scopedSlots:_vm._u([{key:"goods",fn:function(ref){
var record = ref.record;
return _vm._l((record.goodsList),function(item){return _c('item-goods',{staticStyle:{"border-right":"1px solid #F0F0F0"},attrs:{"item":item}})})}},{key:"info",fn:function(ref){
var record = ref.record;
return [_c('div',[_vm._v("订单编号："+_vm._s(record.orderId))]),_c('div',[_vm._v("下单时间："+_vm._s(record.createdAt))])]}},{key:"address",fn:function(ref){
var record = ref.record;
return [_c('div',[_vm._v(_vm._s(JSON.parse(record.addressJson).userName)+" "+_vm._s(JSON.parse(record.addressJson).telNumber))]),_c('div',[_vm._v(" "+_vm._s(JSON.parse(record.addressJson).provinceName)+" "+_vm._s(JSON.parse(record.addressJson).cityName)+" "+_vm._s(JSON.parse(record.addressJson).countyName)+" "+_vm._s(JSON.parse(record.addressJson).detailInfo)+" ")])]}},{key:"operation",fn:function(ref){
var record = ref.record;
return [_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"flex-end"}},[_c('a-button',{staticClass:"order-btn",attrs:{"size":"small"},on:{"click":function($event){return _vm.view(record)}}},[_vm._v("查看详情")]),(record.status==1)?_c('a-button',{staticClass:"order-btn",attrs:{"size":"small"},on:{"click":function($event){return _vm.clickExpress(record)}}},[_vm._v("发货")]):_vm._e(),(record.status==2)?_c('a-button',{staticClass:"order-btn",attrs:{"size":"small"},on:{"click":function($event){return _vm.clickExpress(record)}}},[_vm._v("修改发货信息")]):_vm._e()],1)]}}])},[_c('template',{slot:"search"},[_c('a-radio-group',{on:{"change":function($event){return _vm.$refs.table.handleReset()}},model:{value:(_vm.queryParams.status),callback:function ($$v) {_vm.$set(_vm.queryParams, "status", $$v)},expression:"queryParams.status"}},[_c('a-radio-button',{attrs:{"value":null}},[_vm._v("全部")]),_c('a-radio-button',{attrs:{"value":0}},[_vm._v("未付款")]),_c('a-radio-button',{attrs:{"value":1}},[_vm._v("待发货")]),_c('a-radio-button',{attrs:{"value":2}},[_vm._v("已发货")]),_c('a-radio-button',{attrs:{"value":4}},[_vm._v("退款中")]),_c('a-radio-button',{attrs:{"value":5}},[_vm._v("已退款")]),_c('a-radio-button',{attrs:{"value":3}},[_vm._v("已完成")])],1)],1)],2),_c('input-dialog',{ref:"inputDialog"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }