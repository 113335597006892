<template>
  <div class="goods-item">
    <a-avatar shape="square"  :size="50" :src="item.picture"/>
    <div style="flex: 1;margin-left: 10px">
      <div class="name">{{item.name}}</div>
      <div class="specs">{{item.specs}}</div>
    </div>

    <div>
      <div class="price">￥{{item.price/100}}</div>
      <div class="num">x{{item.num}}</div>
    </div>
  </div>
</template>

<script>
    export default {
        props:["item"]
    }
</script>

<style lang="less" scoped>
  .goods-item{
    display: flex;
    padding: 5px 10px;

    .name{
      color: #333333;
      font-size: 14px;
    }

    .specs{
      margin-top: 2px;
      color: #999999;
      font-size: 13px;
    }

    .price{
      text-align: right;
      color: #333333;
      font-size: 14px;
    }

    .num{
      text-align: right;
      color: #999999;
      font-size: 12px;
    }

  }
</style>
